<template>
  <PaymentLoader v-if="id && loading" :id="id" :token="token" :init-payment="payment" @data="onData" @error="onError">
  </PaymentLoader>

  <ErrorPage v-if="errorcode > 0" :errorcode="errorcode"></ErrorPage>
  <ErrorPage v-if="disablePayment" errorcode="1001" />
  <div v-if="!loading && errorcode === 0 && !disablePayment" class="main">

    <div class="language-selector">
      <LanguageSelector :language="payment.language !== 'NOT_SET' && payment.language !== undefined ?
        payment.language : payment.market" :id="payment.paymentId" :token="token"></LanguageSelector>
    </div>


    <h1 class="pn-xl bold">{{ getHeader() }}</h1>


    <ErrorMessage :error="callbackError"></ErrorMessage>

    <!-- Recipient information -->
    <div class="recipient">
      <div>
        <div class="amount bold">
          {{ formatAmount(amountTmp) }} {{ this.currency }}
        </div>
        <div class="nowrap">
          <span class="regular">{{ $t('payment.to') }}</span> <span class="medium">{{ payment.recipient }}</span>
        </div>
      </div>
      <div>
        <div class="logo">
          <img :alt="$t('payment.logoAlt')" :src="payment.base64logo" class="logo-img" />
        </div>
      </div>
    </div>

    <StatusInfo v-if="paid" :payment="payment" :provider="provider"></StatusInfo>

    <AutoGiro  v-if="paid && payment.optInMethods.includes('AUTOGIRO')" :payment-id="payment.paymentId"/>
    <OptIn v-if="paid && (payment.optInMethods.includes('SMS') || payment.optInMethods.includes('EMAIL'))" :payment-id="payment.paymentId" :methods="payment.optInMethods" :payment-recipient="payment.recipient"
           :token="token"/>


    <div class="mt-2">

      <div v-show="!paid">

        <h2 v-if="!chooseAmount && !multiplePayments && !chooseMethod && method === 'TINK'" class="pn-l bold">{{
          $t('payment.methods.tink.header')
        }}</h2>
        <h2 v-if="!chooseAmount && !multiplePayments && !chooseMethod && method === 'SWISH'" class="pn-l bold">{{
          $t('payment.methods.swish.header')
        }}</h2>
        <h2 v-if="!chooseAmount && !multiplePayments && !chooseMethod && method === 'VIPPS'" class="pn-l bold">{{
          showMobilePay ?  $t('payment.methods.mobilePay.header') : $t('payment.methods.vipps.header')
        }}</h2>
        <h2 v-if="!chooseAmount && !multiplePayments && chooseMethod" class="pn-l bold">{{ $t('payment.chooseMethod') }}
        </h2>
        <h2 v-if="chooseAmount">{{ $t('slider.header') }}</h2>

        <pn-toast v-if="provider === 'TINK'" appearance="info" closable="false" icon="alert-info-circle" v-show="repay">
          {{ $t('payment.repay.tink.info') }}
        </pn-toast>
        <pn-toast v-if="provider === 'SWISH' || provider === 'VIPPS'" appearance="info" closable="false"
          icon="alert-info-circle" v-show="repay">
          {{ $t('payment.repay.direct.info') }}
        </pn-toast>

        <div class="amount-slider" v-if="chooseAmount">
          <AmountSlider :amounts="amounts" :currency="currency" :value="amountTmp" @amount="onAmount" />
        </div>

        <div class="multiple-payment" v-if="multiplePayments">

          <PaymentOption v-for="(p, index) in paymentOptions" :key="p" :title="p.title" :description="p.description"
            :amount="formatAmount(getRecommendedAmount(p))" :currency="currency" :logo="p.base64logo" :value="index"
            :selected="isPaymentOptionSelected(index)" @select="paymentHandler"></PaymentOption>
        </div>

        <div v-if="!chooseAmount && !multiplePayments">

          <div v-if="chooseMethod">

            <PaymentOption v-for="m in paymentMethods" :key="m"
              :title="$t('payment.methods.' + m.toLowerCase() + '.title')"
              :description="$t('payment.methods.' + m.toLowerCase() + '.description')"
              :branding="$t('payment.methods.' + m.toLowerCase() + '.logo')"
              :alt="$t('payment.methods.' + m.toLowerCase() + '.alt')" :value="m" :selected="methodTmp === m"
              @select="methodHandler"></PaymentOption>
          </div>

          <div v-if="!chooseMethod && method === 'TINK'">

            <h3>{{ $t('payment.chooseDate') }}</h3>

            <PaymentOption :title="$t('payment.option.asap')" :description="$t('payment.option.withdrawn')"
              :image="require('../assets/betalaDirekt.png')" value="now" :selected="tinkSelection === 'now'"
              @select="tinkOptionHandler" :disabled="false"></PaymentOption>


            <PaymentOption v-if="displayFuturePayments" :title="$t('payment.option.custom')"
              :description="$t('payment.option.customDescription.' + (tinkSelection === 'custom' ? 'active' : 'inactive'), { date: dateFormat(customDate) })"
              :image="require('../assets/betalaDirekt.png')" value="custom" :selected="tinkSelection === 'custom'"
              @select="tinkOptionHandler" :disabled="false">
              <div v-if="tinkSelection === 'custom'">
                <pn-date-picker ref="datePicker" class="mt-1" range="false" disable-weekends="true" :start="startDate"
                  :min-date="minDate" :disabled-dates="disabledDates" @dateselection="onDateSelect"></pn-date-picker>
              </div>
            </PaymentOption>

            <PaymentOption v-if="displayFuturePayments" :title="$t('payment.option.onduedate')"
              :description="getDueDateText()" :image="require('../assets/betalaSenare.png')" value="ddate"
              :selected="tinkSelection === 'ddate'" @select="tinkOptionHandler" :disabled="dueDateError != null">
            </PaymentOption>

            <pn-toast appearance="info" closable="false" icon="alert-info-circle" v-show="customDateAfterDue">
              {{ $t('payment.customDate') }}
            </pn-toast>
          </div>

          <div v-if="!chooseMethod && method === 'SWISH'">
            <PaymentOption v-if="isMobile" :title="$t('payment.option.openSwish')"
              :description="$t('payment.option.onThisDevice')" :image="require('../assets/betalaDirekt.png')"
              :branding="$t('payment.methods.swish.logo')" :alt="$t('payment.methods.swish.alt')" value="open_app"
              :selected="swishSelection === 'open_app'" @select="swishOptionHandler" :disabled="false"></PaymentOption>

            <PaymentOption :title="$t('payment.option.qrCode')" :description="$t('payment.option.qrDescription')"
              :image="require('../assets/betalaSenare.png')" :branding="$t('payment.methods.swish.logo')"
              :alt="$t('payment.methods.swish.alt')" value="show_qr" :selected="swishSelection === 'show_qr'"
              @select="swishOptionHandler" data-testid="showQr"></PaymentOption>
          </div>

          <div v-if="!chooseMethod && method === 'VIPPS'">
            <PaymentOption
              :title="showMobilePay ? $t('payment.option.openMobilePay') : $t('payment.option.openVipps')"
              :description="showMobilePay ? $t('payment.option.mobilePayOnThisDevice') : $t('payment.option.vippsOnThisDevice')"
              :image="require('../assets/betalaDirekt.png')"
              :branding="showMobilePay ? $t('payment.methods.mobilePay.logo') : $t('payment.methods.vipps.logo')"
              :alt="showMobilePay ? $t('payment.methods.mobilePay.alt') : $t('payment.methods.vipps.alt')"
              value="REDIRECT"
              :selected="vippsSelection === 'REDIRECT'"
              @select="vippsOptionHandler"
              :disabled="false">
            </PaymentOption>
          </div>

        </div>
      </div>

      <PaymentInfo :payment="payment" :amount="formatAmount(amountTmp)" :paymentOption="paymentOptionTmp" :ocr="ocrTmp"
        accordion="true" :provider="provider"></PaymentInfo>

      <ErrorMessage :error="createError"></ErrorMessage>

      <pn-button class="continue" @click="next" v-show="!paid" :loading="createPaymentLoading">
        {{ $t('payment.continue') }}
      </pn-button>

    </div>
  </div>
  <div class="main" v-if="!loading">

    <BrandingInfo />

  </div>
</template>

<script>
import axios from "axios";
import { calculateNextAvailableDate, formatDate } from "../services/dateService.js";
import PaymentOption from "../components/PaymentOption.vue";
import ErrorPage from "../components/ErrorPage.vue";
import ErrorMessage from "../components/ErrorMessage.vue";
import PaymentLoader from "../components/PaymentLoader.vue";
import PaymentInfo from "../components/PaymentInfo.vue";
import BrandingInfo from "../components/BrandingInfo.vue";
import StatusInfo from "../components/StatusInfo.vue";
import AutoGiro from "../components/AutoGiro.vue";
import { BASE_URLS } from '../services/urlService';
import { holidays } from '../holidays';
import hashService from '../services/hashService';
import AmountSlider from '../components/AmountSlider.vue';
import LanguageSelector from "../components/LanguageSelector.vue";
import OptIn from "@/components/OptIn.vue";

const hashMethodValueMap = {
  'SWISH': 'swish',
  'TINK': 'bank',
  'VIPPS': 'vipps'
};

// Options for the observer (which mutations to observe)
const observerConfig = { attributes: true, childList: true, subtree: true };
// Callback function to execute when mutations are observed
const observerCallback = (mutationList) => {
  for (const mutation of mutationList) {
    if (mutation.type === 'attributes' && mutation.attributeName === 'data-open') {
      const calendar = mutation.target;
      const inner = calendar.children[0];
      const open = calendar.hasAttribute('data-open');

      const transitionListener = () => {
        calendar.style.left = '';
        inner.removeEventListener('transitionend', transitionListener);
      };

      if (open) {
        const rect = calendar.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        if (rect.left + rect.width > windowWidth - 10) {
          const left = (windowWidth - rect.width) / 2;
          calendar.style.left = -(rect.left - left) + 'px';
        }
      } else {
        inner.addEventListener('transitionend', transitionListener);
      }
    }
  }
};

// Create an observer instance linked to the callback function
const observer = new MutationObserver(observerCallback);

export default {
  name: "PaymentService",
  props: ['token'],
  components: {
    OptIn,
    AutoGiro,
    PaymentOption,
    ErrorPage,
    ErrorMessage,
    PaymentLoader,
    PaymentInfo,
    BrandingInfo,
    StatusInfo,
    AmountSlider,
    LanguageSelector,
  },
  watch: {
    tinkSelection: {
      handler() {
        setTimeout(() => {
          const dp = this.$refs.datePicker;
          if (dp) {
            observer.observe(dp, observerConfig);
          } else {
            observer.disconnect();
          }
        })
      }
    },
    $route: {
      handler() {
        /**
         * Whenever the hash changes we will call the setState method
         * to update the state of the page
         */
        this.setState();
      }
    }
  },
  data() {
    return {
      // TODO: set from payment object if 'normal' payment to avoid 'choose amount' step
      amounts: null,
      amount: null,
      amountTmp: null,
      currency: null,
      payment: null,
      tinkSelection: null,
      swishSelection: null,
      vippsSelection: 'REDIRECT',
      show: false,
      loading: true,
      status: "",
      paid: false,
      disablePayment: false,
      repay: false,
      errorcode: 0,
      createPaymentLoading: false,
      createError: null,
      callbackError: null,
      pdf: "",
      id: null,
      method: null,
      methodTmp: null,
      ongoingPayment: false,
      provider: "",
      dueDateError: null,
      showDatePopup: false,
      customDate: null,
      startDate: null,
      minDate: null,
      isMobile: false,
      disabledDates: null,
      customDateAfterDue: false,
      paymentMethods: null,
      displayFuturePayments: true,
      chooseAmount: false,
      multiplePayments: false,
      multiplePayment: null,
      paymentOptionTmp: null,
      paymentOption: null,
      ocrTmp: null,
      paymentFeatures: [],
      chooseMethod: false,
      mpoHeader: false,
      showMobilePay: false
    };
  },
  mounted() {

    this.id = this.$route.params.id;
    this.callbackError = this.$route.query.error || null;
    this.isMobile = this.checkUserAgent();
  },
  methods: {
    onAmount(a) {
      this.amountTmp = a;
    },
    onData(e) {
      this.payment = e.data;
      this.sortPaymentMethods(this.payment)

      if (!e.done) {
        if (e.data.ongoingPayment) {
          this.ongoingPayment = true;
        }
        return;
      }

      if (e.aborted) {
        this.method = '';
        this.methodTmp = ''
      }

      if (!e.error && this.ongoingPayment && this.payment.status === 'APPROVED') {
        this.$router.push({
          name: 'callback',
          params: { id: this.payment.paymentId }
        });
        return;
      }

      if (e.error) {
        this.$router.push({
          name: 'payment',
          params: { id: this.payment.paymentId },
          query: { error: e.error }
        });
        this.callbackError = e.error;
      }

      const currentHolidays = holidays[this.payment.market] ? holidays[this.payment.market] : [];

      this.status = this.payment.status;
      this.paid = this.payment.status === 'APPROVED';
      this.provider = this.paid && this.payment.externalPayment.provider || null;
      this.disabledDates = currentHolidays.join(', ');
      this.dueDateError = this.getDueDateError(this.payment, currentHolidays);
      this.displayFuturePayments = !this.payment.customParameters || this.payment.customParameters.noFuturePayments !== 'true';
      this.tinkSelection = (this.dueDateError || !this.displayFuturePayments) ? "now" : "ddate";
      this.showMobilePay = (this.payment.market === 'FI' || this.payment.market === 'DK');

      this.swishSelection = this.isMobile ? "open_app" : "show_qr";
      this.loading = false;
      this.pdf = e.data.invoiceUrl;
      this.paymentFeatures = this.getPaymentFeatures();

      this.currency = this.payment.paymentOptions[0].currency;

      this.amounts = this.payment.paymentOptions[0].amounts;
      this.paymentMethods = this.sortPaymentMethods();
      this.paymentOptions = this.payment.paymentOptions;

      this.shouldDisablePayment();

      /**
       * Custom date should default to the due date or the friday before if weekend.
       * If the friday before is earlier than today date we should use today or monday after
       * if weekend and handle the exceptions
       */
      this.customDate = calculateNextAvailableDate(this.payment.dueDate, this.payment.todayDate, currentHolidays);
      this.startDate = this.customDate;

      /**
       * Min date is the minimum date in the date selector. Should be today date
       * or perhaps tomorrow?
       */
      this.minDate = this.payment.todayDate;

      /**
       * Call this to set the initial state that can depend on hash params
       */
      this.setState();
    },
    getPaymentFeatures() {
      const list = [];
      // check if we should choose amount
      if (this.payment.paymentOptions[0].amounts.length > 1) {
        list.push('CHOOSE_AMOUNT');
      }
      if (this.payment.paymentOptions.length > 1) {
        list.push('CHOOSE_OPTION');
        this.mpoHeader = true;
      }
      if (this.payment.paymentDestinations.length > 1) {
        list.push('CHOOSE_METHOD');
      }
      return list;
    },
    formatAmount(amount) {
      if (typeof amount == 'number') {
        return amount.toFixed(2).replace(/\.00/, '');
      }
      return amount;
    },
    sortPaymentMethods() {
      /**
       * Regardless of which order the available payment methods are in
       * we should have an internal priority of them when presenting them to the user
       */
      const prios = {
        'TINK': 1,
        'SWISH': 2,
        'VIPPS': 3
      };

      let availableProviders = this.payment.paymentDestinations.map(({ provider }) => provider);
      return availableProviders.slice().sort((a, b) => {
        /**
         * If for some reason we don't have mapped a new method
         * we will default the order to 100 so it will
         * end up last in the list
         */
        const sortOrderA = prios[a] || 100;
        const sortOrderB = prios[b] || 100;
        return sortOrderA - sortOrderB;
      });

    },
    setState() {
      /**
       * This method is called initially and whenver the user changes state,
       * i.e. selects a payment method for example or navigates back and forth
       * using the back and forward buttons in the browser
       */
      if (this.loading) {
        return;
      }
      this.amountTmp = this.amount || this.getRecommendedAmount(this.payment.paymentOptions[0]);
      this.amount = this.amountTmp;
      this.paymentOptionTmp = this.paymentOption || this.payment.paymentOptions[0];
      this.paymentOption = this.paymentOptionTmp;
      this.ocrTmp = this.payment.paymentOptions[0].referenceValue;
      this.methodTmp = this.method || this.paymentMethods[0];
      this.method = this.methodTmp;

      if (this.paymentFeatures.indexOf('CHOOSE_AMOUNT') !== -1) {
        const amount = hashService.get(this.$route.hash, 'amount');
        if (amount) {
          this.amount = parseFloat(amount);
          this.amountTmp = this.amount;
          this.chooseAmount = false;
        } else {
          this.chooseAmount = true;
        }
      }

      if (this.paymentFeatures.indexOf('CHOOSE_OPTION') !== -1) {
        const option = hashService.get(this.$route.hash, 'option');
        if (option) {
          this.paymentOption = this.payment.paymentOptions.find(p => p.optionNumber === parseInt(option, 10));
          this.ocrTmp = this.paymentOption.referenceValue;
          this.amount = this.getRecommendedAmount(this.paymentOption);
          this.amountTmp = this.amount;
          this.multiplePayments = false;
        } else {
          this.multiplePayments = true;
          this.mpoHeader = true;
        }
      }

      if (this.paymentFeatures.indexOf('CHOOSE_METHOD') !== -1) {
        const value = hashService.get(this.$route.hash, 'method');
        const method = Object.keys(hashMethodValueMap).filter(k => value === hashMethodValueMap[k])[0] || null;
        if (method) {
          this.method = method;
          this.methodTmp = this.method;
          this.chooseMethod = false;
        } else {
          this.chooseMethod = true;
        }
      }

    },
    getRecommendedAmount(option) {
      if (option.recommendedAmount) {
        return option.recommendedAmount;
      }
      const max = Math.max.apply(null, option.amounts);
      return max;

    },
    isPaymentOptionSelected(index) {
      return this.paymentOptionTmp === this.payment.paymentOptions[index];
    },
    getDueDateError(payment, currentHolidays) {
      if ((payment.warnings && payment.warnings.indexOf('DUE_DATE_IS_HOLIDAY') !== -1) || currentHolidays.indexOf(payment.dueDate) !== -1) {
        return 'holiday';
      }

      if (payment.todayDate > payment.dueDate) {
        return 'passed';
      }

      return null;
    },
    onError(error) {
      this.errorcode = error.response.status || 500;
      this.loading = false;
    },
    dateFormat(value) {
      return formatDate(value, this.$t);
    },
    getRequestConfig() {
      if (!this.token) {
        return undefined;
      }
      return {
        headers: { 'Authorization': 'Bearer ' + this.token }
      };
    },
    next() {

      if (this.chooseAmount) {
        this.amount = this.amountTmp;
        this.$router.push({
          name: 'payment',
          params: { id: this.payment.paymentId },
          hash: hashService.set(this.$route.hash, 'amount', this.amount)
        });
        return;
      }


      if (this.multiplePayments) {
        this.paymentOption = this.paymentOptionTmp;
        this.ocrTmp = this.paymentOption.referenceValue;
        this.$router.push({
          name: 'payment',
          params: { id: this.payment.paymentId },
          hash: hashService.set(this.$route.hash, 'option', this.paymentOption.optionNumber)
        });
        this.mpoHeader = false;
        return;
      }

      /**
       * We have chosen a method
       */
      if (this.method && !this.chooseMethod) {
        this.createPayment(this.method);
      }

      /**
       * No method is chosen. Ignore the click
       */
      if (!this.methodTmp) {
        return;
      }

      /**
       * With swish we will go straight to the payment
       */
      if (this.methodTmp === 'SWISH') {
        this.method = 'SWISH';
        //this.createPayment('SWISH');
      } else if (this.methodTmp === 'VIPPS') {
        this.method = 'VIPPS';
      }

      /**
       * Set method to tink to display next step
       */
      else {
        this.method = 'TINK';
      }

      this.$router.push({
        name: 'payment',
        params: { id: this.payment.paymentId },
        hash: hashService.set(this.$route.hash, 'method', hashMethodValueMap[this.method])
      });

    },
    createPayment(method) {
      this.createPaymentLoading = true;

      const url = `${BASE_URLS.PROCESSOR}/v4/payment/pay/${this.payment.paymentId}`;
      let data = {
        amount: this.amountTmp,
        paymentOptionNumber: this.paymentOption.optionNumber || null,
        provider: method,
        initMethod: this.vippsSelection || null,
        host: window.paymentConfig.host
      };

      if (method === 'TINK') {
        data = {
          ...data,
          executionDate: this.getExecutionDate()
        };
      }

      axios.post(url, data, this.getRequestConfig()).then((resp) => {
        /**
         * For swish we don't want to do a redirect.
         * We will instead trigger the payment loader
         */
        if (method === 'TINK') {
          window.location.href = resp.data.redirectUrl;
          return;
        }

        /**
         * If swish we just trigger the payment loader that will listen for events
         */
        if (method === 'SWISH') {
          this.$router.push({
            name: 'payment',
            params: { id: this.payment.paymentId },
            hash: hashService.set(this.$route.hash, 'swish', this.swishSelection)
          });
          this.loading = true;
        }

        if (method === 'VIPPS' && this.vippsSelection === 'REDIRECT') {
          window.location.href = resp.data.redirectUrl;
        }
      }).catch(err => {
        this.createError = err.response.data.compositeFault.faults[0].faultCode;
      }).finally(() => this.createPaymentLoading = false);
    },
    tinkOptionHandler(e) {
      this.tinkSelection = e;
      if (this.tinkSelection === 'custom') {
        this.showDatePopup = true;
      }
    },
    swishOptionHandler(e) {
      this.swishSelection = e;
    },
    vippsOptionHandler(e) {
      this.vippsSelection = e;
    },
    methodHandler(e) {
      this.methodTmp = e;
    },
    paymentHandler(e) {
      this.paymentOptionTmp = this.payment.paymentOptions[parseInt(e, 10)];
      this.amountTmp = this.getRecommendedAmount(this.paymentOptionTmp);
      this.amount = this.amountTmp;
      this.ocrTmp = this.paymentOptionTmp.referenceValue;

    },
    getDueDateText() {
      return this.$t('payment.dueDate.' + (this.dueDateError ? this.dueDateError : 'normal'), { dueDate: this.dateFormat(this.payment.dueDate) });
    },
    payAgain() {
      this.paid = false;
      this.repay = true;
    },
    getHeader() {
      if (this.mpoHeader) {
        return this.$t('payment.header.mpo');
      }
      return this.$t('payment.header.' + (this.paid ? 'paid' : 'unpaid'));
    },
    onDateSelect(e) {
      this.customDate = e.detail;
      if (this.customDate > this.payment.dueDate) {
        this.customDateAfterDue = true;
      } else {
        this.customDateAfterDue = false;
      }
    },
    getExecutionDate() {
      if (this.tinkSelection === "ddate") {
        return this.payment.dueDate;
      }
      if (this.tinkSelection === "custom") {
        return this.customDate;
      }
      return null;
    },
    setShowQr(e) {
      this.showQr = e;
    },
    checkUserAgent() {
      if (/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/.test(navigator.userAgent)) {
        return true;
      }
    },
    compare(operator, a, b) {
      switch (operator) {
        case '===':
          return a === b;
        case '!==':
          return a !== b;
        case '>':
          return a > b;
        case '<':
          return a < b;
        default:
          console.warn(`Unsupported operator ${operator}`);
          return false;
      }
    },
    getValueByPath(obj, path) {
      const pathArray = path.replace(/\[(\w+)\]/g, '.$1').split('.');
      return pathArray.reduce((acc, key) => {
        return (acc && acc[key] !== undefined) ? acc[key] : undefined;
      }, obj);
    },
    shouldDisablePayment() {
      const config = window.paymentConfig || {};
      let disablePaymentsOn = {};

      if (config.disablePaymentsOn) {
        try {
          disablePaymentsOn = JSON.parse(config.disablePaymentsOn);
        } catch (error) {
          console.error("Failed to parse disablePaymentsOn:", error);
          disablePaymentsOn = {};
        }
      }

      let shouldPaymentBeDisabled = Object.keys(disablePaymentsOn).length > 0 &&
        Object.keys(disablePaymentsOn).every(key => {
          // Use the path to get the actual value from this.payment
          const dataValue = this.getValueByPath(this.payment, key);
          const { value, operator } = disablePaymentsOn[key];

          // Use the retrieved dataValue for comparison
          return this.compare(operator, dataValue, value);
        });

      this.disablePayment = shouldPaymentBeDisabled;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.betalsätt {
  text-align: left;
}

.language-selector {

  /* display: flex;
  align-items: flex-end;
  justify-content: flex-end; */
  float: right;
}

pn-select>pn-option {
  max-width: fit-content;
  max-height: 24px;
}


pn-radio-button {
  font-size: 0.5em;
}

.title {
  color: #5e554a;
}

.text {
  font-size: 0.75em;
  color: #5e554a;
  font-weight: bold;
}

.logo-img {
  width: 100%;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7.2rem;
  height: 7.2rem;
  margin-left: auto;
  position: relative;
}

.logo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #d3cecb;
}

.recipient>div:last-child {
  text-align: right;
  flex-grow: 1;
}

.recipient {
  padding: 1em 0 1em 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1em 0 0 0;
}

pn-button.continue {
  margin-top: 1.5em;
  width: 100%;
}

.amount {
  font-size: 1.5em;
  vertical-align: top;
  margin-bottom: 0.5em;
  font-weight: 700;
}

.nowrap {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}
</style>