const formatDate = (value, $t) => {
  if (value) {

      const [y, m, d] = value.split('-').map(x => parseInt(x, 10));
      const months = {
        0: $t('date.month.jan'),
        1: $t('date.month.feb'),
        2: $t('date.month.mar'),
        3: $t('date.month.apr'),
        4: $t('date.month.may'),
        5: $t('date.month.june'),
        6: $t('date.month.july'),
        7: $t('date.month.aug'),
        8: $t('date.month.sep'),
        9: $t('date.month.oct'),
        10: $t('date.month.nov'),
        11: $t('date.month.dec')
      };

      const dueDate = d + " " + months[m - 1] + " " + y;

      return dueDate;
    }
}

const calculateNextAvailableDate = (startStr, minStr, exceptions) => {
  
  const format = (date) => {
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    return y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
  }

  const parse = (str) => {
    const [year, month, date] = str.split('-').map(x => parseInt(x, 10));
    return new Date(year, month - 1, date);
  }

  const isValid = (d) => {
    if (d.getDay() === 0) {
      // sunday
      return false;
    } 
    if (d.getDay() === 6) {
      // saturday
      return false;
    }
    if (exceptions.indexOf(format(d)) !== -1) {
      // date is in exceptions
      return false;
    }
    // otherwise
    return true;
  }

  /**
   * We need to return the next available date (backwards in time) if startStr (YYYY-MM-DD)
   * is on a weekend or is part of the exceptions but without going past minStr. Otherwise we need to go forward in time
   */

  let date = parse(startStr);
  let min = parse(minStr);

  /**
   * Make sure we use the maximum date as the start
   */
  if (date < min) {
    date = min;
  }

  /**
   * Lets start by moving backwards in time, one day at the time
   */
  let dir = -1;

  while (!isValid(date)) {
    if (dir === -1 && date.getTime() === min.getTime()) {
      // we are on mindate, do not go any further 
      dir = 1;
      date = min;
    }
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + dir);
  }

  return format(date);

}

export { formatDate, calculateNextAvailableDate }
