<template>
  <div :class="getClass()">
    <label>
      <pn-radio-button type="radio" name="payDate" :value="value" class="rbutton" :checked="selected" :disabled="disabled"
        @change="changeHandler" />
      <div class="column">
        <div class="title medium">{{ title }}</div>
        <div class="text regular">
          {{ description }}
        </div>

        <div class="amount" v-show="amount">
          {{ amount }} {{ currency }}
        </div>
        <slot></slot>
      </div>
      <div class="branding" v-if="branding !== null">
          <img :alt="alt" :src="branding" class="branding" />
        </div>
      <div class="logo" v-if="logo">
        <img :alt="$t('payment.logoAlt')" :src="logo" class="logo-img" />
      </div>
    </label>
  </div>
</template>

<style scoped>
.disabled * {
  color: #969087 !important;
}

.paydate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin: 1em auto auto auto;
  padding: 0.5em 0 0.5em 0;
  width: 100%;
  border: 2px solid #f3f2f2;
  border-radius: 0.5em;
  text-align: left;
}

.border {
  border: 2px solid #005d92;
}

label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 1em 0 1em;
  flex-grow: 1;
}

label * {
  box-sizing: content-box;
}

.column {
  flex-direction: column;
  padding: 0 0 0 1em;
}

.title {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.5em;
}

.logo-img {
  max-width: 100%;
  max-height: 100%;
}

.branding {
  max-width: 5rem;
  max-height: 5rem;
  margin-left: auto;
  position: relative;
}

.logo {
  max-width: 7.2rem;
  max-height: 7.2rem;
  margin-left: auto;
  position: relative;
}

.amount {
  font-size: 1.6rem;
  font-weight: 500;
  margin-top: 0.5em;
}

.text {
  font-size: 1.4rem;
  font-weight: 400;
}
</style>
<script>
import { formatDate } from "../services/dateService.js";

export default {
  name: "PaymentOption",
  props: ["selected", "image", "title", "description", "value", "disabled", "amount", "currency", "logo", "branding", "alt"],
  emits: ["select"],

  methods: {
    dateFormat(value) {
      return formatDate(value);
    },
    changeHandler(e) {
      this.$emit("select", e.target.value);
    },
    getClass() {
      let v = "paydate";
      if (this.selected) {
        v += " border";
      }
      if (this.disabled) {
        v += " disabled";
      }
      return v;
    },
  },
};
</script>

